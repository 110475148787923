<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model.number="editedItem.qte_from"
                    :readonly="readonly"
                    label="Qte Debut"
                    :rules="[(v) => !!v || 'Qte Debut obligatoire']"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    dense
                    v-model.number="editedItem.qte_to"
                    :readonly="readonly"
                    label="Qte Fin"
                    :rules="[
                      (v) =>
                        !v ||
                        v > editedItem.qte_from ||
                        'Qte Fin doit être > Qte Debut',
                    ]"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.prix"
                    :readonly="readonly"
                    label="Prix"
                    :rules="[(v) => !!v || 'Prix obligatoire']"
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_TRANCHE from "../graphql/Product/CREATE_PRODUCT_PRIX.gql";
import UPDATE_TRANCHE from "../graphql/Product/UPDATE_PRODUCT_PRIX.gql";

export default {
  components: {},
  name: "qteprixform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    editedItem: {},
    combovalue: "",
  }),

  computed: {
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) this.editedItem = Object.assign({}, this.item);
  },

  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        this.progress = true;

        if (this.editedItem.id > 0) {
          this.$apollo
            .mutate({
              mutation: UPDATE_TRANCHE,

              variables: {
                qteprix: {
                  id: this.editedItem.id,
                  qte_from: this.editedItem.qte_from,
                  qte_to: this.editedItem.qte_to,
                  prix: this.editedItem.prix,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {})
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
          this.progress = false;
          this.items.splice(this.item.index, 1, this.editedItem);
          this.close();
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_TRANCHE,
              variables: {
                qteprix: {
                  produit_id: this.editedItem.produit_id,
                  prix: this.editedItem.prix,
                  qte_from: this.editedItem.qte_from,
                  qte_to: this.editedItem.qte_to,

                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createProductPrice.id;
              this.items.push(this.editedItem);
              this.progress = false;
              this.$emit("add");
              this.close();
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
      }
    },
  },
};
</script>
